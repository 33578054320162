body {
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

@font-face {
  font-family: "Pirate";
  src: local("Pirate"), url(./fonts/pirate.ttf) format("truetype");
}
